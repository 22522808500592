import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { Store } from '@ngrx/store'
import { AppActions } from '@appState/actions'
import { State } from '@appState/index'
import * as fromStore from '@appState/selectors/app.selectors'
import {
   //IProfile,
   ISiteConfiguration,
   IUser
} from '@appShared/interfaces/[Interface-based]'
import { IAccount } from '@appShared/interfaces/[Model-based]/account.interface'
import { IProfile } from '@appShared/interfaces/[Model-based]/profile.interface'
import { IProfileSubmission } from '@appShared/interfaces/[Model-based]/profile-submission.interface'
import { ISubscription } from '@appShared/interfaces/[Model-based]/subscription.interface'

@Injectable({
   providedIn: 'root'
})
export class AppFacade {
   siteConfig$: Observable<ISiteConfiguration> = this._store.select(
      fromStore.getSiteConfig
   )

   user$: Observable<IUser> = this._store.select(fromStore.getCurrentUser)

   //hasVerifiedSSN$: Observable<boolean> = this._store.select(
   //  fromStore.getHasVerifiedSSN
   //)

   //stockPlanUri$: Observable<string> = this._store.select(
   //  fromStore.getStockPlanUri
   //)

   supportEmailAddress$: Observable<string> = this._store.select(
      fromStore.getSupportEmailAddress
   )

   //isAdminOnlyMode$: Observable<boolean> = this._store.select(
   //  fromStore.getIsAdminOnlyMode
   //)

   //areShareholdersAccessible$: Observable<boolean> = this._store.select(
   //  fromStore.getAreShareholdersAccessible
   //)

   constructor(private _store: Store<State>) { }

   loadSiteConfig(): void {
      this._store.dispatch(AppActions.loadSiteConfig())
   }

   prefetchSiteConfig(item?: ISiteConfiguration): void {
      // if haven't gotten store yet, go get it
      if (!item) {
         this.loadSiteConfig()
      }
   }

   setUser(user: IUser): void {
      this._store.dispatch(AppActions.setUser({ user }))
   }

   setUserProfile(profile: IProfile): void {
      this._store.dispatch(AppActions.setUserProfile({ profile }))
   }

   setUserAccount(account: IAccount): void {
      this._store.dispatch(AppActions.setUserAccount({ account }))
   }

   setUserMustResetPassword(mustResetPassword?: boolean): void {
      mustResetPassword = !!(mustResetPassword)
      this._store.dispatch(AppActions.setUserMustResetPassword({ mustResetPassword }))
   }

   //toggleIsAdminOnlyMode(isAdminOnlyMode: boolean): void {
   //  this._store.dispatch(AppActions.toggleIsAdminOnlyMode({ isAdminOnlyMode }))
   //}
}
